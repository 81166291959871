import React, { useRef, useState, Suspense } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { NoToneMapping } from "three";
import { PerspectiveCamera } from "@react-three/drei"; // Import the PerspectiveCamera component
import {
  xyzTextures,
  halfSizeWithGap,
  normalFov,
  middleEle,
  middleWindow,
} from "../utils/Box";
import Box from "./Box";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import { Link } from "gatsby";

const isBrowser = typeof window !== "undefined";

const offset = isBrowser ? +window.innerHeight / 4 : 0;

function ManifestoCamera() {
  const [fov, setFov] = useState(0.8 * normalFov);
  const cameraRef = useRef();

  const canvasEle = document.getElementById("cube-manifesto");

  useFrame(() => {
    if (!isBrowser) return;

    // Check if section is in view
    if (window.scrollY < canvasEle.offsetTop - window.innerHeight) return;

    const middleCanvas = middleEle(canvasEle);
    const middleWindow_ = middleWindow() + 100;

    if (middleWindow_ >= middleCanvas && middleWindow_ < middleCanvas + offset)
      return;

    var newFov;

    if (middleWindow_ < middleCanvas) {
      newFov =
        0.8 *
        normalFov *
        (1 + (2 * (middleCanvas - middleWindow_)) / canvasEle.offsetHeight);
    } else {
      newFov = 0.8 * normalFov;
    }

    setFov(newFov);
  });

  return (
    <PerspectiveCamera
      ref={cameraRef}
      makeDefault // Make this camera the default camera
      fov={fov} // Field of view
      near={0.1} // Near clipping plane
      far={1000} // Far clipping plane
      position={[0, 0, 11]} // Set the camera's initial position
    />
  );
}

function ManifestoScene() {
  const groupRef = useRef();

  const canvasEle = document.getElementById("cube-manifesto");

  function handleScroll() {
    const middleCanvas = middleEle(canvasEle);
    const middleWindow_ = middleWindow() + 100;

    // Check if in view
    if (middleWindow_ < middleCanvas - canvasEle.offsetHeight) return;
    if (middleWindow_ > middleCanvas + canvasEle.offsetHeight) return;

    if (middleWindow_ < middleCanvas) {
      const newLeft =
        10 *
        (1 +
          2 *
            Math.min(
              1,
              (middleCanvas - middleWindow_) / canvasEle.offsetHeight
            ));

      canvasEle.style.left = `${newLeft}%`;
      groupRef.current.rotation.x =
        0.1 - (middleCanvas - middleWindow_) / 400 + Math.PI;
      groupRef.current.rotation.y =
        0.485 +
        Math.PI / 3 +
        (3 * Math.PI) / 4 +
        (middleCanvas - middleWindow_) / 400 +
        Math.PI / 2;
      groupRef.current.rotation.z =
        (Math.PI / 2) * 3 + (middleCanvas - middleWindow_) / 400;
    } else {
      canvasEle.style.left = `10%`;
      groupRef.current.rotation.x = 0.1 + Math.PI;
      groupRef.current.rotation.y =
        0.485 + Math.PI / 3 + (3 * Math.PI) / 4 + Math.PI / 2;
      groupRef.current.rotation.z = (Math.PI / 2) * 3;
    }
  }

  React.useEffect(() => {
    if (!isBrowser) return;

    window.document.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <ambientLight intensity={4} />
      <group ref={groupRef} rotation={[0, 0, 0]}>
        {[-1, 1].map((x) => {
          return [-1, 1].map((y) => {
            return [-1, 1].map((z) => {
              var textures = xyzTextures(x, y, z);
              return (
                <Box
                  key={`manifesto-${x} ${y} ${z}`}
                  onClick={() => {}}
                  position={[
                    x * halfSizeWithGap,
                    y * halfSizeWithGap,
                    z * halfSizeWithGap,
                  ]}
                  textures={textures}
                />
              );
            });
          });
        })}
      </group>
    </>
  );
}

function ManifestoSection({ content, language }) {
  const homeUrl = language === "en" ? "/" : `/${language}/`;
  return (
    <section className="manifesto" id="maze2">
      <div className="section-content">
        <div>
          <h2>{content.title}</h2>
          <div className="catchy">{content.catchphrase}</div>
          {content.paragraphs?.map((paragraph) => (
            <p style={{ whiteSpace: "pre-line" }}>{paragraph.paragraph}</p>
          ))}
          <Link to={homeUrl + "about/"}>
            <button className="mach9">
              <div>
                {content.button}
                <ArrowIcon />
              </div>
            </button>
          </Link>
        </div>
      </div>
      <Canvas
        id="cube-manifesto"
        style={{ width: "50%", aspectRatio: "1 / 1" }}
        linear={true}
        gl={{
          antialias: true,
          alpha: true,
          toneMapping: NoToneMapping,
        }}
      >
        <Suspense fallback={null}>
          <ManifestoCamera />
          <ManifestoScene />
        </Suspense>
      </Canvas>
    </section>
  );
}
export default ManifestoSection;
