import React, { useRef, useState } from "react";
import { middleWindow, bottomWindow } from "../utils/Box";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import { Link } from "gatsby";

const isBrowser = typeof window !== "undefined";

function TechSection({ content }) {
  const [open, setOpen] = useState(false);
  const sectionRef = useRef();

  function handleScroll() {
    if (!sectionRef.current) return;

    if (
      middleWindow() + 200 >
        sectionRef.current.offsetTop + sectionRef.current.offsetHeight / 2 &&
      bottomWindow() <
        sectionRef.current.offsetTop + sectionRef.current.offsetHeight
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    if (!isBrowser) return;
    window.document.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className="ny-data" id="maze" ref={sectionRef}>
      <div>
        <div>
          <h2>{content.title}</h2>
          <div className="catchy highlighted">{content.catchphrase}</div>
          <div>{content.description}</div>
        </div>
        <div className={"faces" + (open ? " opened" : "")}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div style={{ textAlign: "right", marginTop: "5.5rem" }}>
          <Link to={content.link}>
            <button className="mach9">
              <div>
                {content.button}
                <ArrowIcon />
              </div>
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default TechSection;
