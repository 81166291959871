import React from "react";

function PartnersSection({ content }) {
  return (
    <section className="tech-logos" id="globe">
      <div>
        <div style={{ textAlign: "center" }}>
          <h2>{content.title}</h2>
          <div className="">{content.catchphrase}</div>
        </div>
        <div className="carousel">
          <div className="carousel__wrapper">
            {content.companylogos.map((ele) => {
              return (
                <div
                  className="carousel__slide"
                  key={`carousel-${ele.logo.publicURL}-1`}
                >
                  <div
                    className="carousel__image"
                    style={{
                      backgroundImage: `url(${encodeURI(ele.logo.publicURL)})`,
                    }}
                  ></div>
                </div>
              );
            })}
            {content.companylogos.map((ele) => (
              <div
                className="carousel__slide"
                key={`carousel-${ele.logo.publicURL}-2`}
              >
                <div
                  className="carousel__image"
                  style={{
                    backgroundImage: `url(${encodeURI(ele.logo.publicURL)})`,
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnersSection;
