import React, { useRef, useState, Suspense } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { NoToneMapping } from "three";
import { PerspectiveCamera } from "@react-three/drei"; // Import the PerspectiveCamera component
import {
  assetServer,
  xyzTextures,
  halfSizeWithGap,
  normalFov,
  middleEle,
  naturalPosition,
  middleWindow,
} from "../utils/Box";
import Box from "./Box";

const isBrowser = typeof window !== "undefined";

function NyScene() {
  const groupRef = useRef();

  return (
    <>
      <ambientLight intensity={4} />
      <group
        ref={groupRef}
        rotation={[
          naturalPosition["ny"].x,
          naturalPosition["ny"].y,
          naturalPosition["ny"].z,
        ]}
      >
        {[-1, 1].map((x) => {
          return [-1, 1].map((y) => {
            return [-1, 1].map((z) => {
              var textures = xyzTextures(x, y, z);
              return (
                <Box
                  key={`ny-${x} ${y} ${z}`}
                  onClick={() => {}}
                  position={[
                    x * halfSizeWithGap,
                    y * halfSizeWithGap,
                    z * halfSizeWithGap,
                  ]}
                  textures={textures}
                />
              );
            });
          });
        })}
      </group>
    </>
  );
}

function NyCamera() {
  const [fov, setFov] = useState(0.6 * normalFov);
  const cameraRef = useRef();

  const canvasEle = document.getElementById("cube-ny");

  useFrame(() => {
    if (!isBrowser) return;

    // Check if section is in view
    if (window.scrollY < canvasEle.offsetTop - window.innerHeight) return;

    const middleCanvas = middleEle(canvasEle);
    const middleWindow_ = middleWindow() + 300;

    var newFov;

    if (middleWindow_ < middleCanvas) {
      newFov =
        0.6 *
        normalFov *
        (1 + (2 * (middleCanvas - middleWindow_)) / canvasEle.offsetHeight);
    } else {
      newFov = 0.6 * normalFov;
    }

    setFov(newFov);
  });

  return (
    <PerspectiveCamera
      ref={cameraRef}
      makeDefault // Make this camera the default camera
      fov={fov} // Field of view
      near={0.1} // Near clipping plane
      far={1000} // Far clipping plane
      position={[0, 0, 11]} // Set the camera's initial position
    />
  );
}

function NySection({ content }) {
  const [showVideo, setShowVideo] = useState(false);
  const [canvasEle, setCanvasEle] = useState(null);
  const [hideCube, setHideCube] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const showScroll = 200;

  function onscroll() {
    if (!canvasEle) return;

    if (
      middleWindow() + 400 >= middleEle(canvasEle) &&
      middleWindow() < middleEle(canvasEle) + showScroll
    ) {
      setShowVideo(true);
    } else {
      //if (middleWindow() < middleEle(canvasEle))
      setShowVideo(false);
    }

    if (middleWindow() + 400 >= middleEle(canvasEle)) {
      setHideCube(true);
    } else {
      setHideCube(false);
    }
  }

  React.useEffect(() => {
    setCanvasEle(document.getElementById("cube-ny"));
    setFirstRender(false);
  }, []);

  React.useEffect(() => {
    if (!isBrowser) return;

    if (canvasEle) window.document.addEventListener("scroll", onscroll);
  }, [canvasEle]);

  React.useEffect(() => {
    if (firstRender) return;

    const videoEle = document.getElementById("video-ny");
    if (!videoEle) return;

    if (showVideo) {
      videoEle.play();
    } else {
      videoEle.pause();
    }
  }, [showVideo]);

  return (
    <section className="ny" id="ny">
      <div className="section-content">
        <div style={{ textAlign: "center" }}>
          <h2>{content.title}</h2>
          <div className="catchy highlighted">{content.catchphrase}</div>
        </div>
        <div
          id="cube-ny"
          style={{
            position: "relative",
            width: "100vw",
          }}
        >
          <Canvas
            style={{
              width: "100%",
              height: "100dvh",
              ...(hideCube ? { visibility: "hidden" } : {}),
            }}
            linear={true}
            gl={{
              antialias: true,
              alpha: true,
              toneMapping: NoToneMapping,
            }}
          >
            <Suspense fallback={null}>
              <NyCamera />
              <NyScene />
            </Suspense>
          </Canvas>
        </div>
        <div
          className={"video-container" + (showVideo ? " fade-in" : " fade-out")}
        >
          <video
            width="320"
            height="240"
            id="video-ny"
            controls={true}
            loop={true}
            muted={true}
            preload={"auto"}
            src={assetServer + "/videos/mach9.mp4"}
            type="video/mp4"
            style={{ width: "100%", height: "100%" }}
          ></video>
        </div>
        {/* <p style={{ height: `${showScroll}px`, width: "100%" }}></p> */}
      </div>
    </section>
  );
}

export default NySection;
