import React, { useRef, useState, Suspense } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { NoToneMapping } from "three";
import { PerspectiveCamera } from "@react-three/drei"; // Import the PerspectiveCamera component
import {
  xyzTextures,
  halfSizeWithGap,
  normalFov,
  middleEle,
  naturalPosition,
  middleWindow,
} from "../utils/Box";
import Box from "./Box";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import { Link } from "gatsby";

const isBrowser = typeof window !== "undefined";

const offset = isBrowser ? +window.innerHeight / 4 : 0;

function GlobalCamera() {
  const [fov, setFov] = useState(0.8 * normalFov);
  const cameraRef = useRef();

  const canvasEle = document.getElementById("cube-global");

  useFrame(() => {
    if (!isBrowser) return;

    // Check if section is in view
    if (window.scrollY < canvasEle.offsetTop - window.innerHeight) return;

    const middleCanvas = middleEle(canvasEle);
    const middleWindow_ = middleWindow() + 400;

    if (middleWindow_ >= middleCanvas && middleWindow_ < middleCanvas + offset)
      return;

    var newFov;

    if (middleWindow_ < middleCanvas) {
      newFov =
        0.8 *
        normalFov *
        (1 + (2 * (middleCanvas - middleWindow_)) / canvasEle.offsetHeight);
    } else {
      newFov = 0.8 * normalFov;
    }

    setFov(newFov);
  });

  return (
    <PerspectiveCamera
      ref={cameraRef}
      makeDefault // Make this camera the default camera
      fov={fov} // Field of view
      near={0.1} // Near clipping plane
      far={1000} // Far clipping plane
      position={[0, 0, 11]} // Set the camera's initial position
    />
  );
}

function GlobalScene() {
  const groupRef = useRef();

  const canvasEle = document.getElementById("cube-global");

  function handleScroll() {
    const middleCanvas = middleEle(canvasEle);
    const middleWindow_ = middleWindow() + 400;

    if (middleWindow_ < middleCanvas - canvasEle.offsetHeight) return;
    if (middleWindow_ > middleCanvas + canvasEle.offsetHeight) return; // It is not in view

    if (
      middleWindow_ >= middleCanvas &&
      middleWindow_ < middleCanvas + offset
    ) {
      const newLeft = 50;

      groupRef.current.rotation.x = naturalPosition["map"].x;
      groupRef.current.rotation.y = naturalPosition["map"].y;
      groupRef.current.rotation.z = naturalPosition["map"].z;

      canvasEle.style.left = `${newLeft}%`;
    } else if (middleWindow_ < middleCanvas) {
      const newLeft =
        50 *
        (1 +
          2 *
            Math.min(
              1,
              (middleCanvas - middleWindow_) / canvasEle.offsetHeight
            ));

      canvasEle.style.left = `${newLeft}%`;

      groupRef.current.rotation.x =
        naturalPosition["map"].x + (middleCanvas - middleWindow_) / 200;
      groupRef.current.rotation.y =
        naturalPosition["map"].y + (middleCanvas - middleWindow_) / 200;
      groupRef.current.rotation.z =
        naturalPosition["map"].z + (middleCanvas - middleWindow_) / 200;
    } else {
      canvasEle.style.left = `50%`;

      groupRef.current.rotation.x = naturalPosition["map"].x;
      groupRef.current.rotation.y = naturalPosition["map"].y;
      groupRef.current.rotation.z = naturalPosition["map"].z;
    }
  }

  React.useEffect(() => {
    if (!isBrowser) return;

    window.document.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <ambientLight intensity={4} />
      <group ref={groupRef} rotation={[0, 0, 0]}>
        {[-1, 1].map((x) => {
          return [-1, 1].map((y) => {
            return [-1, 1].map((z) => {
              var textures = xyzTextures(x, y, z, true);
              return (
                <Box
                  key={`global-${x} ${y} ${z}`}
                  onClick={() => {}}
                  position={[
                    x * halfSizeWithGap,
                    y * halfSizeWithGap,
                    z * halfSizeWithGap,
                  ]}
                  textures={textures}
                />
              );
            });
          });
        })}
      </group>
    </>
  );
}

function GlobalSection({ content, language }) {
  const [showLocations, setShowLocations] = React.useState(true);
  const homeUrl = language === "en" ? "/" : `/${language}/`;

  function handleScroll() {
    if (
      middleWindow() >=
      -400 + middleEle(document.getElementById("cube-global"))
    ) {
      setShowLocations(true);
    } else {
      setShowLocations(false);
    }
  }

  React.useEffect(() => {
    if (!isBrowser) return;

    window.document.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className="global" id="global">
      <div className="section-content">
        <div>
          <h2 style={{ textAlign: "center" }}>{content.title}</h2>
          <div className="catchy highlighted" style={{ textAlign: "center" }}>
            {content.catchphrase}
          </div>
          <p
            style={{
              width: "60rem",
              maxWidth: "100%",
              textAlign: "center",
            }}
          >
            {content.description}
          </p>
          <div style={{ textAlign: "center" }}>
            <Link to={homeUrl + "atlas/#atlas-contact"}>
              <button className="mach9">
                <div>
                  {content.button}
                  <ArrowIcon />
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div
        id="cube-global"
        style={{
          position: "relative",
        }}
      >
        <Canvas
          style={{
            width: "100%",
            maxWidth: "800px",
            margin: "auto",
            aspectRatio: "1 / 1",
          }}
          linear={true}
          gl={{
            antialias: true,
            alpha: true,
            toneMapping: NoToneMapping,
          }}
        >
          <Suspense fallback={null}>
            <GlobalCamera />
            <GlobalScene />
          </Suspense>
        </Canvas>
        <div className={"location ny" + (showLocations ? "" : " hidden")}>
          <span style={{ fontSize: "120%" }}>NEW YORK CITY</span>
          <br />
          <span>MACH9 HQ</span>
        </div>
        <div className={"location la" + (showLocations ? "" : " hidden")}>
          <span style={{ fontSize: "120%" }}>LOS ANGELES</span>
          <br />
          <span>MACH9 WEST COAST</span>
        </div>
        <div className={"location mexico" + (showLocations ? "" : " hidden")}>
          <span style={{ fontSize: "120%" }}>MEXICO CITY</span>
          <br />
          <span>MACH9 LATAM</span>
        </div>
        <div className={"location torino" + (showLocations ? "" : " hidden")}>
          <span style={{ fontSize: "120%" }}>BRESCIA</span>
          <br />
          <span>MACH9 EUROPE</span>
        </div>
        <div className={"location zurich" + (showLocations ? "" : " hidden")}>
          <span style={{ fontSize: "120%" }}>ZURICH</span>
          <br />
          <span>MACH9 TECH HUB</span>
        </div>
        <div className={"location zagreb" + (showLocations ? "" : " hidden")}>
          <span style={{ fontSize: "120%" }}>ZAGREB</span>
          <br />
          <span>MACH9 DESIGN STUDIO</span>
        </div>
        {/* <div className={"location ny" + (showLocations ? "" : " hidden")}>
          New York
        </div>
        <div className={"location mexico" + (showLocations ? "" : " hidden")}>
          Mexico
        </div>
        <div className={"location zurich" + (showLocations ? "" : " hidden")}>
          Zürich
        </div> */}
      </div>
    </section>
  );
}
export default GlobalSection;
