import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import WelcomeSection from "../components/WelcomeSection";

import Layout from "../components/Layout";
import ManifestoSection from "../components/ManifestoSection";
import GlobalSection from "../components/GlobalSection";
import NySection from "../components/NySection";
import TechSection from "../components/TechSection";
import PartnersSection from "../components/PartnersSection";
import RegulatedSection from "../components/RegulatedSection";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  welcomepitch,
  manifestopitch,
  globalfootprintpitch,
  nypitch,
  techpitch,
  partnerspitch,
  regulatedpitch,
  language,
}) => {

  return (
    <>
      <WelcomeSection content={welcomepitch} />
      <ManifestoSection content={manifestopitch} language={language} />
      <GlobalSection content={globalfootprintpitch} language={language} />
      <NySection content={nypitch} />
      <TechSection content={techpitch} />
      <PartnersSection content={partnerspitch} />
      <RegulatedSection content={regulatedpitch} language={language} />
    </>
  );
};

IndexPageTemplate.propTypes = {
  welcomepitch: PropTypes.object,
  manifestopitch: PropTypes.object,
};

const IndexPage = ({ data, pageContext, path }) => {
  const { frontmatter } = data.markdownRemark;
  const frontmatterFromLanguage = frontmatter[pageContext.language || "en"];

  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <IndexPageTemplate
        welcomepitch={frontmatterFromLanguage.welcomepitch}
        manifestopitch={frontmatterFromLanguage.manifestopitch}
        globalfootprintpitch={frontmatterFromLanguage.globalfootprintpitch}
        nypitch={frontmatterFromLanguage.nypitch}
        techpitch={frontmatterFromLanguage.techpitch}
        partnerspitch={frontmatterFromLanguage.partnerspitch}
        regulatedpitch={frontmatterFromLanguage.regulatedpitch}
        language={pageContext.language || "en"}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { en: { templateKey: { eq: "index-page" } } }) {
      frontmatter {
        en {
          welcomepitch {
            title1
            title2
            scroll
            rotate
            face1
            face2
            face3
            face4
            face5
            face6
          }
          manifestopitch {
            title
            catchphrase
            paragraphs {
              paragraph
            }
            button
          }
          globalfootprintpitch {
            title
            catchphrase
            description
            button
          }
          nypitch {
            title
            catchphrase
          }
          techpitch {
            title
            catchphrase
            button
            link
            description
          }
          partnerspitch {
            title
            catchphrase
            companylogos {
              logo {
                publicURL
              }
            }
          }
          regulatedpitch {
            title
            catchphrase
            description
            button
            cards {
              title
              description
              cta
              picture
            }
            modal {
              title0
              title1
              title2
              buttonyes
              buttonno
              description
            }
          }
        }
        it {
          welcomepitch {
            title1
            title2
            scroll
            rotate
            face1
            face2
            face3
            face4
            face5
            face6
          }
          manifestopitch {
            title
            catchphrase
            paragraphs {
              paragraph
            }
            button
          }
          globalfootprintpitch {
            title
            catchphrase
            description
            button
          }
          nypitch {
            title
            catchphrase
          }
          techpitch {
            title
            catchphrase
            button
            link
            description
          }
          partnerspitch {
            title
            catchphrase
            companylogos {
              logo {
                publicURL
              }
            }
          }
          regulatedpitch {
            title
            catchphrase
            description
            button
            cards {
              title
              description
              cta
              picture
            }
            modal {
              title0
              title1
              title2
              buttonyes
              buttonno
              description
            }
          }
        }
        es {
          welcomepitch {
            title1
            title2
            scroll
            rotate
            face1
            face2
            face3
            face4
            face5
            face6
          }
          manifestopitch {
            title
            catchphrase
            paragraphs {
              paragraph
            }
            button
          }
          globalfootprintpitch {
            title
            catchphrase
            description
            button
          }
          nypitch {
            title
            catchphrase
          }
          techpitch {
            title
            catchphrase
            button
            link
            description
          }
          partnerspitch {
            title
            catchphrase
            companylogos {
              logo {
                publicURL
              }
            }
          }
          regulatedpitch {
            title
            catchphrase
            description
            button
            cards {
              title
              description
              cta
              picture
            }
            modal {
              title1
              title2
              buttonyes
              buttonno
              description
            }
          }
        }
      }
    }
  }
`;
