import React, { useEffect, useRef, useState } from "react";
import { useFrame, useLoader, useThree } from "react-three-fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { FrontSide, LinearFilter } from "three";
import { textureFileServer } from "../utils/Box";

const faceSize = 2;
const gap = 0;
const halfSizeWithGap = faceSize / 2 + gap / 2;

const orientations = [
  { position: [0, 0, halfSizeWithGap], rotation: [0, 0, 0], label: "map" },
  {
    position: [0, 0, -halfSizeWithGap],
    rotation: [0, Math.PI, 0],
    label: "ny",
  },
  {
    position: [halfSizeWithGap, 0, 0],
    rotation: [0, Math.PI / 2, 0],
    label: "keyhole",
  },
  {
    position: [-halfSizeWithGap, 0, 0],
    rotation: [0, -Math.PI / 2, 0],
    label: "globe",
  },
  {
    position: [0, halfSizeWithGap, 0],
    rotation: [-Math.PI / 2, 0, 0],
    label: "maze",
  },
  {
    position: [0, -halfSizeWithGap, 0],
    rotation: [Math.PI / 2, 0, 0],
    label: "maze2",
  },
];

const fallbackTexture = "globe-bottom-left.png";

function Box(props) {
  const [hovered, setHovered] = useState(null);
  const groupRef = useRef();

  var texturesMap = [null, null, null, null, null, null];
  const { raycaster, mouse, camera } = useThree();

  texturesMap[0] = useLoader(
    TextureLoader,
    props.textures[0] === null
      ? textureFileServer + fallbackTexture
      : props.textures[0].file
  );

  texturesMap[1] = useLoader(
    TextureLoader,
    props.textures[1] === null
      ? textureFileServer + fallbackTexture
      : props.textures[1].file
  );

  texturesMap[2] = useLoader(
    TextureLoader,
    props.textures[2] === null
      ? textureFileServer + fallbackTexture
      : props.textures[2].file
  );

  texturesMap[3] = useLoader(
    TextureLoader,
    props.textures[3] === null
      ? textureFileServer + fallbackTexture
      : props.textures[3].file
  );

  texturesMap[4] = useLoader(
    TextureLoader,
    props.textures[4] === null
      ? textureFileServer + fallbackTexture
      : props.textures[4].file
  );

  texturesMap[5] = useLoader(
    TextureLoader,
    props.textures[5] === null
      ? textureFileServer + fallbackTexture
      : props.textures[5].file
  );

  for (let i = 0; i < texturesMap.length; i++) {
    texturesMap[i].minFilter = texturesMap[i].magFilter = LinearFilter;
  }

  useEffect(() => {
    if (hovered) props.onHover(hovered);
  }, [hovered]);

  useFrame(() => {
    if (props.onHover) {
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObject(groupRef.current);

      if (intersects.length === 1) {
        setHovered(intersects[0].object?.name);
      }
    }
  });

  return (
    <group ref={groupRef} position={props.position}>
      {props.textures.map((texture, index) => {
        if (texture === null) return null;

        const finalPositions = orientations[index].position.map(
          (positionValue, positionIndex) => {
            return positionValue;
          }
        );

        return (
          <mesh
            key={Math.random()}
            position={finalPositions}
            rotation={orientations[index].rotation}
            onClick={() => {
              props.onClick(orientations[index].label);
            }}
            name={orientations[index].label}
            onHover={() => alert("hovered")}
          >
            <planeGeometry attach="geometry" args={[faceSize, faceSize]} />
            <meshStandardMaterial
              attach="material"
              side={FrontSide}
              map={texturesMap[index]}
            />
          </mesh>
        );
      })}
    </group>
  );
}

export default Box;
