import React, { useRef, useState, Suspense, useEffect } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { NoToneMapping } from "three";
import { PerspectiveCamera } from "@react-three/drei"; // Import the PerspectiveCamera component
import {
  xyzTextures,
  halfSizeWithGap,
  normalFov,
  middleEle,
  naturalPosition,
  middleWindow,
} from "../utils/Box";
import Box from "./Box";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import ArrowLeft from "/assets/icons/mui/arrow-left.svg";
import ArrowRight from "/assets/icons/mui/arrow-right.svg";
import { Link } from "gatsby";

const isBrowser = typeof window !== "undefined";

function RegulatedCamera({ setShowCards }) {
  const [fov, setFov] = useState(1.2 * normalFov);
  const cameraRef = useRef();

  const canvasEle = document.getElementById("cube-tech-databases");

  useFrame(() => {
    if (!isBrowser) return;

    // Check if section is in view
    if (window.scrollY < canvasEle.offsetTop - window.innerHeight) {
      return;
    }

    const middleCanvas = middleEle(canvasEle);
    const middleWindow_ = middleWindow() + 400;

    if (middleWindow_ > middleCanvas) {
      setShowCards(true);
      return; // It is not in view
    }

    var newFov;
    newFov =
      1.2 *
      normalFov *
      (1 + (2 * (middleCanvas - middleWindow_)) / canvasEle.offsetHeight);

    setFov(newFov);
    setShowCards(false);
  });

  return (
    <PerspectiveCamera
      ref={cameraRef}
      makeDefault // Make this camera the default camera
      fov={fov} // Field of view
      near={0.1} // Near clipping plane
      far={1000} // Far clipping plane
      position={[0, 0, 11]} // Set the camera's initial position
    />
  );
}

function RegulatedScene() {
  const groupRef = useRef();

  const canvasEle = document.getElementById("cube-tech-databases");

  function handleScroll() {
    const middleCanvas = middleEle(canvasEle);
    const middleWindow_ = middleWindow() + 400;

    if (middleWindow_ < middleCanvas - canvasEle.offsetHeight) return;
    if (middleWindow_ > middleCanvas) {
      canvasEle.style.left = `0`;
      groupRef.current.rotation.x = -Math.PI / 3 + naturalPosition["maze"].x;
      groupRef.current.rotation.y = Math.PI / 3 + naturalPosition["maze"].y;
      groupRef.current.rotation.z = Math.PI / 2.75 + naturalPosition["maze"].z;

      const progressFactor = 1;
      const value = 1 + 0.3 * progressFactor;

      groupRef.current.children[6].position.x = value;
      groupRef.current.children[6].position.z = -value;
      groupRef.current.children[7].position.x = value;
      groupRef.current.children[7].position.z = value;
      groupRef.current.children[2].position.x = -value;
      groupRef.current.children[2].position.z = -value;
      groupRef.current.children[3].position.x = -value;
      groupRef.current.children[3].position.z = value;

      groupRef.current.children[6].rotation.y = (Math.PI / 12) * progressFactor;
      groupRef.current.children[7].rotation.y =
        -(Math.PI / 12) * progressFactor;
      groupRef.current.children[2].rotation.y =
        -(Math.PI / 12) * progressFactor;
      groupRef.current.children[3].rotation.y = (Math.PI / 12) * progressFactor;
      return; // It is not in view
    }
    const newLeft =
      50 *
      (0 -
        2 *
          Math.min(1, (middleCanvas - middleWindow_) / canvasEle.offsetHeight));
    canvasEle.style.left = `${newLeft}%`;

    groupRef.current.rotation.x =
      -Math.PI / 3 +
      naturalPosition["maze"].x +
      (middleCanvas - middleWindow_) / 200;
    groupRef.current.rotation.y =
      Math.PI / 3 +
      naturalPosition["maze"].y +
      (middleCanvas - middleWindow_) / 200;
    groupRef.current.rotation.z =
      Math.PI / 2.75 +
      naturalPosition["maze"].z +
      (middleCanvas - middleWindow_) / 200;

    const progressFactor = Math.min(
      1,
      Math.max(0, 1 - (middleCanvas - middleWindow_) / 40)
    );
    const value = 1 + 0.3 * progressFactor;

    groupRef.current.children[6].position.x = value;
    groupRef.current.children[6].position.z = -value;
    groupRef.current.children[7].position.x = value;
    groupRef.current.children[7].position.z = value;
    groupRef.current.children[2].position.x = -value;
    groupRef.current.children[2].position.z = -value;
    groupRef.current.children[3].position.x = -value;
    groupRef.current.children[3].position.z = value;

    groupRef.current.children[6].rotation.y = (Math.PI / 12) * progressFactor;
    groupRef.current.children[7].rotation.y = -(Math.PI / 12) * progressFactor;
    groupRef.current.children[2].rotation.y = -(Math.PI / 12) * progressFactor;
    groupRef.current.children[3].rotation.y = (Math.PI / 12) * progressFactor;
  }

  React.useEffect(() => {
    if (!isBrowser) return;

    window.document.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <ambientLight intensity={4} />
      <group ref={groupRef} rotation={[0, 0, 0]}>
        {[-1, 1].map((x) => {
          return [-1, 1].map((y) => {
            return [-1, 1].map((z) => {
              var textures = xyzTextures(x, y, z);
              return (
                <Box
                  key={`tech-db-${x} ${y} ${z}`}
                  onClick={() => {}}
                  position={[
                    x * halfSizeWithGap,
                    y * halfSizeWithGap,
                    z * halfSizeWithGap,
                  ]}
                  textures={textures}
                />
              );
            });
          });
        })}
      </group>
    </>
  );
}

function RegulatedSection({ content, language }) {
  const [drinkingAge, setDrinkingAge] = useState(null);
  const [currentCard, setCurrentCard] = useState(1);
  const [showCards, setShowCards] = useState(false);
  const homeUrl = language === "en" ? "/" : `/${language}/`;

  useEffect(() => {
    if (!content.cards?.length) return;

    const interval = setInterval(() => {
      const ele = document.getElementById("cards");

      ele.scroll({
        left: ele.offsetWidth * (currentCard - 1) + 300,
        behavior: "smooth",
      });

      setCurrentCard((currentCard + 1) % content.cards.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentCard]);


  const handleNextCard = () => {
    if (!content.cards?.length) return;

    const ele = document.getElementById("cards");

    ele.scroll({
      left: ele.offsetWidth * (currentCard - 1) + 300,
      behavior: "smooth",
    });

    setCurrentCard((currentCard + 1) % content.cards.length);
  };

  const handlePrevCard = () => {
    if (!content.cards?.length) return;

    // if (currentCard > 0) {
    const ele = document.getElementById("cards");

    ele.scroll({
      left: ele.offsetWidth * (currentCard - 1) + 300,
      behavior: "smooth",
    });

    setCurrentCard((currentCard - 1) % content.cards.length);
  };

  return (
    <>
      <div className={"blocking-modal" + (!drinkingAge ? "" : " hide")}>
    
    <h2 style={{marginBottom: "100px"}}>{content.modal.title0}</h2>
        
        <div>
          <div>
            <h2>
              {content.modal.title1}
              <br />
              {content.modal.title2}
            </h2>
            {drinkingAge === null && (
              <div style={{ display: "flex", gap: "1rem" }}>
                <button className="mach9" onClick={() => setDrinkingAge(true)}>
                  <div>{content.modal.buttonyes}</div>
                </button>
                <button className="mach9" onClick={() => setDrinkingAge(false)}>
                  <div>{content.modal.buttonno}</div>
                </button>
              </div>
            )}
            <div style={{ marginTop: "1rem" }}>{content.modal.description}</div>
            <div className="left neon"></div>
            <div className="top neon"></div>
            <div className="right neon"></div>
            <div className="bottom neon"></div>
          </div>
        </div>
      </div>
      <section
        className={"tech-databases" + (!drinkingAge ? " blocked" : "")}
        id="keyhole"
      >
        <div>
          <div>
            <h2>{content.title}</h2>
            {/* <div className="catchy highlighted">{content.catchphrase}</div> */}
            <p style={{ maxWidth: "50rem" }}>{content.description}</p>
            <div style={{ marginBlock: "2rem" }}>
              <Link to={homeUrl + "highly-regulated/"}>
                <button className="mach9">
                  <div>
                    {content.button}
                    <ArrowIcon />
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div
          className="regulated-projector"
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              className={"projector" + (showCards ? " show" : " hide")}
            ></div>
            <Canvas
              id="cube-tech-databases"
              style={{
                width: "50vw",
                aspectRatio: "1 / 1",
                paddingBottom: "4rem",
              }}
              linear={true}
              gl={{
                antialias: true,
                alpha: true,
                toneMapping: NoToneMapping,
              }}
            >
              <Suspense fallback={null}>
                <RegulatedCamera setShowCards={(show) => setShowCards(show)} />
                <RegulatedScene />
              </Suspense>
            </Canvas>

            <div id="cube-tech-databases-right">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div onClick={handlePrevCard} style={{ cursor: "pointer" }}>
                  <ArrowLeft />
                </div>
                <div
                  className={"cards" + (showCards ? " show" : " hide")}
                  id="cards"
                >
                  {content.cards?.map((card, i) => (
                    <div
                      className="card"
                      key={`card-${i}`}
                      id={`card-${i}`}
                      
                    >
                      <div
                        className="bg"
                        style={{
                          backgroundImage:
                            "URL(/static/assets/regulated/" +
                            card.picture +
                            ")",
                        }}
                      />
                      <div className="content">
                        <h3>{card.title}</h3>
                        <p>{card.description}</p>

                        <Link to={homeUrl + "highly-regulated/"}>
                          <button className="mach9 no-outline">
                            <div>
                              {card.cta} <ArrowIcon />
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>

                <div onClick={handleNextCard} style={{ cursor: "pointer" }}>
                  <ArrowRight />
                </div>
              </div>
              <div
                className={"card-selector" + (showCards ? " show" : " hide")}
              >
                {content.cards?.map((_, i) => {
                  var currentI = currentCard - 1;
                  if (currentCard === 0) currentI = content.cards.length - 1;

                  if (i === currentI)
                    return (
                      <div
                        key={`card-selector-${i}-selected`}
                        className="selected"
                      ></div>
                    );

                  return (
                    <div
                      key={`card-selector-${i}`}
                      onClick={() => {
                        document.getElementById("cards").scroll({
                          left:
                            document.getElementById("cards").offsetWidth * i,
                          behavior: "instant",
                        });
                        setCurrentCard(i + 1);
                      }}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegulatedSection;
