import React, { useRef, useState, Suspense } from "react";
import { Canvas } from "react-three-fiber";
import { NoToneMapping } from "three";
import { PerspectiveCamera } from "@react-three/drei"; // Import the PerspectiveCamera component
import {
  xyzTextures,
  halfSizeWithGap,
  rotationStep,
  naturalPosition,
} from "../utils/Box";
import Box from "./Box";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import RotateIcon from "/assets/icons/rotate.svg";
import ArrowDown from "/assets/icons/arrow-down.svg";

const isBrowser = typeof window !== "undefined";
var mouseIsDown = false;
var touchStart = null;

function WelcomeScene({ onSelect }) {
  const [selectedFace, setSelectedFace] = useState(null);
  const [hoveredFace, setHoveredFace] = useState(null);
  const groupRef = useRef();

  const canvasEle = document.getElementById("cube-welcome");

  React.useEffect(() => {
    if (isBrowser && groupRef.current) {
      canvasEle.addEventListener(
        "mousedown",
        (e) => {
          mouseIsDown = true;
        },
        false
      );
      window.addEventListener(
        "mousemove",
        (e) => {
          if (mouseIsDown) {
            groupRef.current.rotation.x =
              groupRef.current.rotation.x + e.movementY / 150;
            groupRef.current.rotation.y =
              groupRef.current.rotation.y + e.movementX / 150;

            if (groupRef.current.rotation.x < 0)
              groupRef.current.rotation.x =
                Math.PI * 2 + groupRef.current.rotation.x;
            if (groupRef.current.rotation.y < 0)
              groupRef.current.rotation.y =
                Math.PI * 2 + groupRef.current.rotation.y;
            if (groupRef.current.rotation.z < 0)
              groupRef.current.rotation.z =
                Math.PI * 2 + groupRef.current.rotation.z;

            if (groupRef.current.rotation.x > Math.PI * 2)
              groupRef.current.rotation.x =
                -Math.PI * 2 + groupRef.current.rotation.x;
            if (groupRef.current.rotation.y > Math.PI * 2)
              groupRef.current.rotation.y =
                -Math.PI * 2 + groupRef.current.rotation.y;
            if (groupRef.current.rotation.z > Math.PI * 2)
              groupRef.current.rotation.z =
                -Math.PI * 2 + groupRef.current.rotation.z;
          }
        },
        false
      );
      window.addEventListener(
        "mouseup",
        (e) => {
          mouseIsDown = false;
        },
        false
      );

      canvasEle.addEventListener("touchstart", (e) => {
        touchStart = e.changedTouches[0];
      });

      canvasEle.addEventListener("touchend", (e) => {
        touchStart = e.changedTouches[0];
      });

      canvasEle.addEventListener("touchmove", (e) => {
        e.preventDefault();

        const movementX = e.changedTouches[0].clientX - touchStart.clientX;
        const movementY = e.changedTouches[0].clientY - touchStart.clientY;

        groupRef.current.rotation.x =
          groupRef.current.rotation.x + movementY / 100;
        groupRef.current.rotation.y =
          groupRef.current.rotation.y + movementX / 100;

        if (groupRef.current.rotation.x < 0)
          groupRef.current.rotation.x =
            Math.PI * 2 + groupRef.current.rotation.x;
        if (groupRef.current.rotation.y < 0)
          groupRef.current.rotation.y =
            Math.PI * 2 + groupRef.current.rotation.y;
        if (groupRef.current.rotation.z < 0)
          groupRef.current.rotation.z =
            Math.PI * 2 + groupRef.current.rotation.z;

        if (groupRef.current.rotation.x > Math.PI * 2)
          groupRef.current.rotation.x =
            -Math.PI * 2 + groupRef.current.rotation.x;
        if (groupRef.current.rotation.y > Math.PI * 2)
          groupRef.current.rotation.y =
            -Math.PI * 2 + groupRef.current.rotation.y;
        if (groupRef.current.rotation.z > Math.PI * 2)
          groupRef.current.rotation.z =
            -Math.PI * 2 + groupRef.current.rotation.z;
        touchStart = e.changedTouches[0];
      });

      canvasEle.addEventListener("wheel", (event) => {
        if (window.scrollY > 0) return;

        event.preventDefault();
        event.stopImmediatePropagation();

        if (event.deltaY > 0) {
          // We break it in 10 steps to make the rotation smoother
          for (let i = 0; i < 10; i++) {
            groupRef.current.rotation.x =
              (groupRef.current.rotation.x + rotationStep / 10) % (Math.PI * 2);
            groupRef.current.rotation.y =
              (groupRef.current.rotation.y + rotationStep / 10) % (Math.PI * 2);
          }
        } else {
          for (let i = 0; i < 10; i++) {
            groupRef.current.rotation.x =
              (groupRef.current.rotation.x - rotationStep / 10) % (Math.PI * 2);
            groupRef.current.rotation.y =
              (groupRef.current.rotation.y - rotationStep / 10) % (Math.PI * 2);
          }
        }
      });

      // window.document.addEventListener("scroll", handleScroll);
    }
  }, []);

  function smallestRotation(target, current) {
    if (Math.abs(target - current) < 2 * Math.PI - Math.abs(target - current)) {
      return target - current;
    } else {
      return 2 * Math.PI - (target - current);
    }
  }

  function scheduleRotation(targetRotation) {
    const rotationX = smallestRotation(
      targetRotation.x,
      groupRef.current.rotation.x
    );
    const rotationY = smallestRotation(
      targetRotation.y,
      groupRef.current.rotation.y
    );
    const rotationZ = smallestRotation(
      targetRotation.z,
      groupRef.current.rotation.z
    );

    setTimeout(() => {
      if (
        Math.abs(rotationX) > 0.01 ||
        Math.abs(rotationY) > 0.01 ||
        Math.abs(rotationZ) > 0.01
      ) {
        rotate(rotationX / 10, rotationY / 10, rotationZ / 10);
        scheduleRotation(targetRotation);
      } else {
        rotate(rotationX, rotationY, rotationZ);
      }
    }, 10);
  }

  function rotate(rotationX, rotationY, rotationZ) {
    groupRef.current.rotation.x =
      (groupRef.current.rotation.x + rotationX) % (Math.PI * 2);
    groupRef.current.rotation.y =
      (groupRef.current.rotation.y + rotationY) % (Math.PI * 2);
    groupRef.current.rotation.z =
      (groupRef.current.rotation.z + rotationZ) % (Math.PI * 2);
  }

  function rotateToFace(face) {
    if (!groupRef.current) return;

    const targetRotation = naturalPosition[face];

    scheduleRotation(targetRotation);
  }

  React.useEffect(() => {
    if (selectedFace) {
      rotateToFace(selectedFace);
      // onSelect(selectedFace);
    }
  }, [selectedFace]);

  React.useEffect(() => {
    if (hoveredFace) {
      onSelect(hoveredFace);
    }
  }, [hoveredFace]);

  return (
    <>
      <ambientLight intensity={4} />
      <group ref={groupRef} rotation={[Math.PI / 6, Math.PI / 4, 0]}>
        {[-1, 1].map((x) => {
          return [-1, 1].map((y) => {
            return [-1, 1].map((z) => {
              var textures = xyzTextures(x, y, z);

              return (
                <Box
                  key={`welcome-${x} ${y} ${z}`}
                  position={[
                    x * halfSizeWithGap,
                    y * halfSizeWithGap,
                    z * halfSizeWithGap,
                  ]}
                  textures={textures}
                  onClick={(face) => {
                    setSelectedFace(face);
                  }}
                  onHover={(face) => {
                    setHoveredFace(face);
                  }}
                />
              );
            });
          });
        })}
      </group>
    </>
  );
}

function WelcomeCamera() {
  const cameraRef = useRef();

  return (
    <PerspectiveCamera
      ref={cameraRef}
      makeDefault // Make this camera the default camera
      fov={40} // Field of view
      near={0.1} // Near clipping plane
      far={1000} // Far clipping plane
      position={[0, 0, 11]} // Set the camera's initial position
    />
  );
}

function WelcomeSection({ content }) {
  const [selectedFace, setSelectedFace] = useState(null);

  const sectionsInfo = {
    map: { label: content.face1, id: "global" },
    maze2: { label: content.face2, id: "maze2" },
    globe: { label: content.face3, id: "globe" },
    keyhole: { label: content.face4, id: "keyhole" },
    ny: { label: content.face5, id: "ny" },
    maze: { label: content.face6, id: "maze" },
  };

  return (
    <section className="first" id="firstSection">
      <Canvas
        id="cube-welcome"
        style={{
          aspectRatio: "1 / 1",
        }}
        linear={true}
        gl={{
          antialias: true,
          alpha: true,
          toneMapping: NoToneMapping,
        }}
      >
        <Suspense fallback={null}>
          <WelcomeCamera />
          <WelcomeScene onSelect={(face) => setSelectedFace(face)} />
        </Suspense>
      </Canvas>
      <div className="rotate">
        {content.rotate} <RotateIcon />
      </div>
      {selectedFace && (
        <div className="face-selected">
          <button
            className="mach9"
            onClick={() => {
              const ele = document.getElementById(
                sectionsInfo[selectedFace].id
              );
              ele.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <div>
              {sectionsInfo[selectedFace].label}
              <ArrowIcon />
            </div>
          </button>
        </div>
      )}
      <h1 className="title">
        <span style={{ zIndex: -1 }}>{content?.title1}</span>
        <span>{content?.title2}</span>
      </h1>
      <div className="scroll">
        {content?.scroll} <span style={{ marginLeft: "0.5rem" }}>&#8595;</span>
      </div>
    </section>
  );
}

export default WelcomeSection;
