export const assetServer = "/static/assets";
export const textureFileServer = assetServer + "/new/";
export const faceSize = 2;
export const gap = 0;
export const halfSizeWithGap = faceSize / 2 + gap / 2;
export const rotationStep = 0.1;
export const normalFov = 40;

export const naturalPosition = {
  map: { x: 0, y: 0, z: 0 },
  maze2: { x: Math.PI / 2, y: (Math.PI * 3) / 2, z: Math.PI },
  globe: { x: (Math.PI * 3) / 2, y: Math.PI, z: Math.PI / 2 },
  keyhole: { x: Math.PI / 2, y: (3 * Math.PI) / 2, z: Math.PI / 2 },
  ny: { x: 0, y: Math.PI, z: 0 },
  maze: { x: Math.PI / 2, y: Math.PI / 2, z: 0 },
};

export function middleEle(ele) {
  return ele?.offsetTop + ele.offsetHeight / 2;
}

export function middleWindow() {
  return window.scrollY + window.innerHeight / 2;
}

export function bottomWindow() {
  return window.scrollY + window.innerHeight / 2;
}

const textureFiles = {
  ny: {
    topLeft: "NY-top-left.png",
    topRight: "NY-top-right.png",
    bottomLeft: "NY-bottom-left.png",
    bottomRight: "NY-bottom-right.png",
  },
  globe: {
    topLeft: "globe-top-left.png",
    topRight: "globe-top-right.png",
    bottomLeft: "globe-bottom-left.png",
    bottomRight: "globe-bottom-right.png",
  },
  keyhole: {
    topLeft: "keyhole-top-left.png",
    topRight: "keyhole-top-right.png",
    bottomLeft: "keyhole-bottom-left.png",
    bottomRight: "keyhole-bottom-right.png",
  },
  map: {
    topLeft: "map-top-left.png",
    topRight: "map-top-right.png",
    bottomLeft: "map-bottom-left.png",
    bottomRight: "map-bottom-right.png",
  },
  mapRedDot: {
    topLeft: "map-top-left.png",
    topRight: "map-top-right.png",
    bottomLeft: "map-bottom-left.png",
    bottomRight: "map-bottom-right.png",
  },
  maze: {
    topLeft: "maze-top-left.png",
    topRight: "maze-top-right.png",
    bottomLeft: "maze-bottom-left.png",
    bottomRight: "maze-bottom-right.png",
  },
  maze2: {
    topLeft: "maze-2-top-left.png",
    topRight: "maze-2-top-right.png",
    bottomLeft: "maze-2-bottom-left.png",
    bottomRight: "maze-2-bottom-right.png",
  },
};

export function xyzTextures(x, y, z, mapRedDot) {
  var textures;

  if (x === -1 && y === -1 && z === -1) {
    textures = [
      null, // not needed as long as we dont open it
      {
        file: textureFileServer + textureFiles.ny.bottomRight,
        label: "ny",
      },
      null, // not needed
      {
        file: textureFileServer + textureFiles.globe.bottomLeft,
        label: "globe",
      },
      null, // not needed
      {
        file: textureFileServer + textureFiles.maze2.bottomLeft,
        label: "maze2",
      },
    ];
  } else if (x === -1 && y === -1 && z === 1) {
    textures = [
      {
        file:
          textureFileServer +
          (mapRedDot
            ? textureFiles.mapRedDot.bottomLeft
            : textureFiles.map.bottomLeft),
        label: "map",
      },
      null,
      null,
      {
        file: textureFileServer + textureFiles.globe.bottomRight,
        label: "globe",
      },
      null,
      {
        file: textureFileServer + textureFiles.maze2.topLeft,
        label: "maze2",
      },
    ];
  } else if (x === -1 && y === 1 && z === -1) {
    textures = [
      null, // not needed
      {
        file: textureFileServer + textureFiles.ny.topRight,
        label: "ny",
      },
      null, // Not needed
      {
        file: textureFileServer + textureFiles.globe.topLeft,
        label: "globe",
      },
      {
        file: textureFileServer + textureFiles.maze.topLeft,
        label: "maze",
      },
      null, // Not needed
    ];
  } else if (x === -1 && y === 1 && z === 1) {
    textures = [
      {
        file:
          textureFileServer +
          (mapRedDot
            ? textureFiles.mapRedDot.topLeft
            : textureFiles.map.topLeft),
        label: "map",
      },
      null,
      null,
      {
        file: textureFileServer + textureFiles.globe.topRight,
        label: "globe",
      },
      {
        file: textureFileServer + textureFiles.maze.bottomLeft,
        label: "maze",
      },
      null,
    ];
  } else if (x === 1 && y === -1 && z === -1) {
    textures = [
      null,
      {
        file: textureFileServer + textureFiles.ny.bottomLeft,
        label: "ny",
      },
      {
        file: textureFileServer + textureFiles.keyhole.bottomRight,
        label: "keyhole",
      },
      null,
      null,
      {
        file: textureFileServer + textureFiles.maze2.bottomRight,
        label: "maze2",
      },
    ];
  } else if (x === 1 && y === -1 && z === 1) {
    textures = [
      {
        file:
          textureFileServer +
          (mapRedDot
            ? textureFiles.mapRedDot.bottomRight
            : textureFiles.map.bottomRight),
        label: "map",
      },
      null,
      {
        file: textureFileServer + textureFiles.keyhole.bottomLeft,
        label: "keyhole",
      },
      null,
      null,
      {
        file: textureFileServer + textureFiles.maze2.topRight,
        label: "maze2",
      },
    ];
  } else if (x === 1 && y === 1 && z === -1) {
    textures = [
      null,
      {
        file: textureFileServer + textureFiles.ny.topLeft,
        label: "ny",
      },
      {
        file: textureFileServer + textureFiles.keyhole.topRight,
        label: "keyhole",
      },
      null,
      {
        file: textureFileServer + textureFiles.maze.topRight,
        label: "maze",
      },
      null,
    ];
  } else if (x === 1 && y === 1 && z === 1) {
    textures = [
      {
        file:
          textureFileServer +
          (mapRedDot
            ? textureFiles.mapRedDot.topRight
            : textureFiles.map.topRight),
        label: "map",
      }, //
      null,
      {
        file: textureFileServer + textureFiles.keyhole.topLeft,
        label: "keyhole",
      },
      null,
      {
        file: textureFileServer + textureFiles.maze.bottomRight,
        label: "maze",
      },
      null,
    ];
  }

  return textures;
}
